import axios from 'axios'
import {store} from './store/store'

export default function transactionChecking(to,next) {
  const oldTransac = localStorage.getItem('sbeeTransaction')
  const token = localStorage.getItem('token')
  if(oldTransac) {
    axios.post(`transaction/checkTransaction/${oldTransac}`)
    .then(res =>{
        const meterInfo = res.data
        
        let meterCode = meterInfo.code
        const purchaseData = {
            counterNum: meterInfo.meterNum,
            amount: meterInfo.amount,
            codeSts: meterCode,
            kwh: meterInfo.kwh,
            entretien: meterInfo.fees,
            dettes: meterInfo.debt,
            tva: meterInfo.tva,
            fraisMtn: meterInfo.transacFees,
            total: meterInfo.total,
            id: meterInfo.transacId
        }
        if(token) {
          axios.post(`/users/check`, {token: token})
          .then(res =>{
            console.log(res)
            const userData = res.data
            store.commit('mutAuthUser', userData)
            store.commit('mutDashPaySuccessData', purchaseData)
            if (to.path !== '/dashboard/success') {
              next('/dashboard/success');
            } else {
              next();
            }
              
          })
          .catch(err =>{
            console.log(err)
            store.commit('mutPaySuccessData', purchaseData)
            if (to.path !== '/recharges/bigframe') {
              next('/recharges/bigframe');
            } else {
              next();
            }
          })
        }
        else {
          store.commit('mutPaySuccessData', purchaseData)
          if (to.path !== '/recharges/bigframe') {
            next('/recharges/bigframe');
          } else {
            next();
          }
        }
        
    })
    .catch(err =>{
        console.log(err)
        if(token) {
          axios.post(`/users/check`, {token: token})
          .then(res =>{
            console.log(res)
            const userData = res.data
            store.commit('mutAuthUser', userData)
            store.commit('mutDashboardPleaseWait', true)
            next('/dashboard/recharge')
          })
          .catch(err =>{
            console.log(err)
            store.commit('mutPleaseWaitStep', true)
            next('/recharges')
          })
        }
        else {
          store.commit('mutPleaseWaitStep', true)
          next('/recharges')
        }
    })
  }
}

