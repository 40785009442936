/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/visacircle': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<path pid="0" d="M19.19 10.5a9.19 9.19 0 11-18.38 0 9.19 9.19 0 0118.38 0z" _fill="#222F5A"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20 10.5c0 5.523-4.477 10-10 10s-10-4.477-10-10S4.477.5 10 .5s10 4.477 10 10zm-10 9.19a9.19 9.19 0 100-18.38 9.19 9.19 0 000 18.38z" _fill="#fff"/><path pid="2" d="M9.827 9.332c-.008.58.568.904 1.001 1.096.446.198.596.324.594.501-.003.27-.355.389-.685.395-.575.008-.91-.142-1.175-.255l-.208.884c.267.112.761.21 1.273.214 1.204 0 1.99-.54 1.993-1.38.005-1.063-1.615-1.121-1.604-1.597.004-.144.155-.298.486-.337a2.347 2.347 0 011.128.18l.201-.854A3.342 3.342 0 0011.761 8c-1.132 0-1.928.548-1.934 1.332zm4.938-1.258a.558.558 0 00-.294.08.487.487 0 00-.193.215l-1.717 3.735h1.203l.239-.6h1.468l.139.6h1.057l-.924-4.03h-.978zm.168 1.089l.347 1.513h-.95l.603-1.513zM8.37 8.073l-.947 4.03h1.145l.946-4.03H8.37zm-1.693 0l-1.192 2.744-.482-2.333a.523.523 0 00-.528-.41H2.527L2.5 8.19c.4.079.854.206 1.13.343.168.083.216.156.271.353l.913 3.217h1.21l1.855-4.03H6.676" _fill="#fff"/>'
  }
})
