import axios from 'axios'
import {store} from './store/store'

export default function authGuard(next) {
    const token = localStorage.getItem('token')
    axios.post(`/users/check`, {
        token: token
    })
    .then(res =>{
        console.log(res)
        const userData = res.data
        store.commit('mutAuthUser', userData)
        next()
        
    })
    .catch(err =>{
        console.log(err)
        next('/recharges/signsuccess')
    })
}