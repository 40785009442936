/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/chevronDate': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.22 2.97a.75.75 0 011.06 0l4.5 4.5a.75.75 0 010 1.06l-4.5 4.5a.75.75 0 01-1.06-1.06L9.19 8 5.22 4.03a.75.75 0 010-1.06z" _fill="#000"/>'
  }
})
