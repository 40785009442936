/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/redarrow': {
    width: 63,
    height: 43,
    viewBox: '0 0 63 43',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 26.657l32.785 3.058V43l29.988-21.5L32.785 0v13.285L0 16.343v10.313z" _fill="#F11C33"/>'
  }
})
