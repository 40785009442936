const state = {
    firstModal:false,
    secondModal:false
   
};

const getters = {
    getFirstModal: state => {
        console.log('nnnnn');
        return state.firstModal
    },
    getSecondModal(state){
        return state.secondModal
    }
    
}

const mutations = {
    openModal(state){
        console.log('openModal',state.firstModal);
        state.firstModal = true

      },
      openSecondModal(state){
        console.log('openModal',state.firstModal);
        state.secondModal = true

      },

}
const actions ={
    

}


export default{
    actions,
    state,
    getters,
    mutations

}