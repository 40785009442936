/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/chevronforward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.83 4.455c.439-.44 1.151-.44 1.59 0l6.75 6.75c.44.439.44 1.151 0 1.59l-6.75 6.75a1.125 1.125 0 01-1.59-1.59L13.784 12 7.829 6.045a1.125 1.125 0 010-1.59z" _fill="#333"/>'
  }
})
