/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/logout': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M6.25 10a.625.625 0 01.625-.625H12.5V5.312c0-1.25-1.32-2.187-2.5-2.187H4.062a2.19 2.19 0 00-2.187 2.188v9.375a2.19 2.19 0 002.188 2.187h6.25a2.19 2.19 0 002.187-2.188v-4.062H6.875A.625.625 0 016.25 10zm11.692-.442l-3.125-3.125a.625.625 0 00-.884.884l2.058 2.058H12.5v1.25h3.491l-2.058 2.058a.625.625 0 00-.148.689.626.626 0 00.587.39.625.625 0 00.445-.195l3.125-3.125a.625.625 0 000-.884z" _fill="#F11C33"/>'
  }
})
