<template>
    <div class="h-auto text-center" style="background:#F5F6F6;">
        <div class="p-4 sm:h-32 h-auto flex flex-row items-center justify-center" style="background:#222F5A;">
            <div class=" flex flex-col sm:flex-row justify-center items-center" >
                <div>
                    <p class="text-white text-xl sm:text-3xl font-semibold sm:mr-8 text-center mb-3">
                        {{ $t("footer.title") }}
                    </p>
                </div>
                <div>
                    <a href="https://kkiapay.me" target="blank">
                        <button class="text-white px-8 py-2 w-full rounded  bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 focus:outline-none">{{ $t("footer.titleBtn") }}</button>
                    </a>
                </div>
            </div>
        </div> 
        <div class="p-4 flex flex-col items-center">
            <div class="flex flex-col sm:flex-row items-center mb-8">
                <p class="hidden sm:inline sm:text-2xl font-semibold mr-4 mb-4" style="color:#333333">{{ $t("footer.plateformeAgree") }}</p>
                <p class="sm:hidden text-base font-semibold mr-4 mb-4" style="color:#333333">{{ $t("footer.plateformeAgreemb") }}</p>
                <span class="sm:mb-4"><Pci/></span>
            </div>
            <div class=" hidden sm:w-full sm:flex flex-row justify-around text-left mb-8">
                <div>
                    <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">{{ $t("footer.about") }}</h5>
                    <ul class="mt-1" style="color: #4D4C4C">
                        <li><a href="https://kkiapay.me/entreprise" target="blank">{{ $t("footer.entreprise") }}</a></li>
                        <li><a href="https://kkiapay.me/conformite" target="blank">{{ $t("footer.autorisation") }}</a></li>
                        <li><a href="https://kkiapay.me/termes-and-conditions" target="blank">{{ $t("footer.condition") }}</a></li>
                    </ul>
                </div>
                <div>
                    <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">{{ $t("footer.kkiapayAbout") }}</h5>
                    <ul class="mt-1" style="color: #4D4C4C">
                        <li><a href="https://kkiapay.me/pricing" target="blank">{{ $t("footer.kOffers") }}</a></li>
                        <li><a href="https://app.kkiapay.me/sign-up/" target="blank">{{ $t("footer.createAccount") }}</a></li>
                    </ul>
                </div>
                <div>
                    <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">{{ $t("footer.helpCenter") }}</h5>
                    <ul class="mt-1" style="color: #4D4C4C">
                        <li><button onclick="$crisp.push(['do', 'chat:toggle'])" class="focus:outline-none">{{ $t("footer.askQuiz") }}</button></li>
                        <li><a href="mailto:support@kkiapay.me">{{ $t("footer.writeUs") }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="sm:hidden w-full flex flex-col sm:flex-row sm:justify-around items-center text-center mb-8">               
                <div class="mb-6">
                    <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">{{ $t("footer.about") }}</h5>
                    <ul class="mt-1" style="color: #4D4C4C">
                        <li><a href="https://kkiapay.me/entreprise" target="blank">{{ $t("footer.entreprise") }}</a></li>
                        <li><a href="https://kkiapay.me/conformite" target="blank">{{ $t("footer.autorisation") }}</a></li>
                        <li><a href="https://kkiapay.me/termes-and-conditions" target="blank">{{ $t("footer.condition") }}</a></li>
                    </ul>
                </div>
                <div class="mb-6">
                    <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">{{ $t("footer.utilLinks") }}</h5>
                    <ul class="mt-1" style="color: #4D4C4C">
                        <li class="mb-1"><a href="https://sbee.bj" target="blank">www.sbee.bj</a></li>
                        <li class="mb-1"><a href="https://energie.gouv.bj" target="blank">energie.gouv.bj</a></li>
                    </ul>
                </div>
                <div class="mb-6">
                    <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">{{ $t("footer.helpCenter") }}</h5>
                    <ul class="mt-1" style="color: #4D4C4C">
                        <li><button onclick="$crisp.push(['do', 'chat:toggle'])" class="focus:outline-none">{{ $t("footer.askQuiz") }}</button></li>
                        <li><a href="mailto:support@kkiapay.me">{{ $t("footer.writeUs") }}</a></li>
                    </ul>
                </div>
            </div>
            <div style="margin-left:11%">
                <div class="flex flex-col items-center text-center sm:flex-row">
                    <p class="text-base sm:text-sm font-semibold sm:mr-2" style="color:#333333">{{ $t("footer.trademarque") }} </p>
                    <!-- <p class="text-base sm:text-sm font-semibold sm:hidden inline" style="color:#333333">{{ $t("footer.opencondition") }}</p> -->
                    <a href="http://opensi.co/" target="blank"><OpenSi/></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OpenSi from '../../assets/svg/opensi.svg?inline'
import Pci from '../../assets/svg/pci.svg?inline'
export default {
    components: {
        OpenSi,
        Pci
    }
}
</script>

<style scoped>
.pkk{
font-weight: 600; 
font-size: 30px; 
line-height: 32px;
font-family: Source Sans Pro;
font-style: normal;
}
</style>