import Vue from 'vue';
import './assets/styles/tailwind.css';
import VueRouter from 'vue-router';
import App from './App.vue';
import {routes} from './routes';
import {store} from './store/store'
import VeeValidate from "vee-validate";
import { Validator } from 'vee-validate';
import axios from 'axios';
/* import VueTelInput from 'vue-tel-input' */
import Meta from 'vue-meta'
import authGuard from './authGuard'
import transactionChecking from './checkTransaction'
import { socket } from "./io";

socket.on('connect', function(){
  console.log('Connected');
  
})

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL

export const billRechargeApi = axios.create({
         baseURL:
           process.env.VUE_APP_POSTPAID_URL || "https://recharges.bj/invoice",
         //headers: {},
         /* withCredentials: true,
  Origin: 'http://momo-api.connectors.kkiapay.me',
  Host: 'momo-api.connectors.kkiapay.me' */
       });
Validator.extend('upCase', {
  getMessage: () => "One uppercase character",
  validate: value => value.match(/[A-Z]+/g) !== null
})

Validator.extend('minAmount', {
  getMessage: () => "Le montant de recharge minimum est de 500",
  validate: value => value >= 500
})

Validator.extend('number', {
  getMessage: () => "One number",
  validate: value => value.match(/[0-9]+/g) !== null
})

Validator.extend('charac', {
  getMessage: () => "One characarcter",
  //validate: value => value.match(/^(?=.*[a-zA-Z0-9])/g) !== null
  validate: value => value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g) !== null
})



//import VCalendar from 'v-calendar';

import i18n from './i18n'
import SvgIcon from 'vue-svgicon'
 
// Default tag name is 'svgicon'
Vue.use(SvgIcon, {
  tagName: 'svgicon',
  isOriginalDefault: true
})
 
/* Vue.use(VCalendar, {
  componentPrefix: 'vc',
}); */

//Vue.use(VueTelInput)
Vue.config.productionTip = false

Vue.use(Meta)

Vue.use(VeeValidate);
Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: 'hash',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } 
      return { x: 0, y: 0 }
    },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    authGuard(next)
  } else {
    next() 
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.socketRoute)) {
    next()
  }
  else {
    transactionChecking(to, next)
    next()
  }
  
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

