import axios from 'axios'
import { store } from '../store'
import { DateTime } from "luxon";
const state = {
    counters: [],
    postpaidCounters: [],
    searchCounters: [],
    searchPostCounters: [],
    addCounter: false,
    editCounter: false,
    meterToEdit: {
        numb: '',
        id: null
    },
    trashCounter: false,
    counterId: '',
    dashPaySuccessData: {
        counterNum: null,
        amount: null,
        codeSts: null,
        kwh: null,
        entretien: null,
        dettes: null,
        tva: null,
        fraisMtn: null,
        total: null,
        id: null
    },
    selectedMeter: {
        cNumb: null,
        cUser: null,
        cFamily: null
    },
    debt: null,
};

const getters = {
    getAddCounter: state => {
        return state.addCounter
    },
    getEditCounter: state => {
        return state.editCounter
    },
    getMeterToEdit: state => {
        return state.meterToEdit
    },
    getTrashCounter: state => {
        return state.trashCounter
    },
    getCounter: state => {
        return state.counters
    },
    getPostpaidCounters: state => state.postpaidCounters,
    getPostCounter: state => state.postCounters,
    getSearchCounter: state => {
        return state.searchCounters
    },
    getSearchPostCounter: state => state.searchPostCounters,
    getCounterHasLength: state => {
        const length = state.counters.length
        if (length != 0) return true
        if (length == 0) {
            return false
        }

    },
    getCounterId: state => {
        return state.counterId
    },
    getDashPaySuccessData: state => {
        return state.dashPaySuccessData
    },
    getSelectedMeter: state => {
        return state.selectedMeter
    },
    getMeterDebt: state => {
        return state.debt
    },
}

const mutations = {
    mutCheckArrear(state) {
        axios.post('/transaction', {
            meterNumb: state.selectedMeter.cNumb,
            amount: '1'
        })
            .then(async res => {
                console.log(res)
                const data = res.data
                const debt = parseFloat(data.arrearAMT) + parseFloat(data.feeAMT)
                state.debt = debt
            })
            .catch(err => {
                console.log(err)
            })
    },
    mutMeterDebt(state, debt) {
        state.debt = debt
    },
    mutSelectedMeter(state, meter) {
        state.debt = null
        state.selectedMeter = {
            cNumb: meter.cNumb,
            cUser: meter.cUser,
            cFamily: meter.cFamily
        }
    },
    mutDashPaySuccessData: (state, purchaseData) => {
        state.dashPaySuccessData = {
            counterNum: purchaseData.counterNum,
            amount: purchaseData.amount,
            codeSts: purchaseData.codeSts,
            kwh: purchaseData.kwh,
            entretien: purchaseData.entretien,
            dettes: purchaseData.dettes,
            tva: purchaseData.tva,
            fraisMtn: purchaseData.fraisMtn,
            total: purchaseData.total,
            id: purchaseData.id
        }
    },
    mutAddCounter(state, bool) {
        state.addCounter = bool
    },
    mutEditCounter(state, bool) {
        state.editCounter = bool
    },
    mutMeterToEdit(state, meter) {
        state.meterToEdit = {
            numb: meter.numb,
            id: meter.id
        }
    },
    mutTrashCounter(state, bool) {
        state.trashCounter = bool
    },
    mutCounterId(state, id) {
        state.counterId = id
    },
    mutCountersUpdate(state) {
        //const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        axios({
            method: 'delete',

            url: `/meters/${state.counterId}`,

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                console.log(res.data)
                state.counters = state.counters.filter(
                    counter => {
                        return counter.id != state.counterId;
                    }
                );
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutManyCountersDelete(state, metersIds) {
        //const userId = store.getters.getAuthUser.id
        console.log('okkkk', metersIds)
        const token = store.getters.getAuthUser.token
        axios({
            method: 'post',

            url: `/meters/deleteManyMeters`,

            data: metersIds,

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                console.log(res.data)
                metersIds.forEach(id => {
                    state.counters = state.counters.filter(
                        counter => {
                            return counter.id != id;
                        }
                    );
                });
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutGenerateCounters(state) {
        state.counters = [];
        const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        axios({
            method: 'get',

            url: `/meters/user/${userId}`,

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                //console.log(res.data)
                const meters = res.data
                meters.forEach(meter => {
                    const date = new Date(meter.createdAt).toISOString()
                    const formatDate = DateTime.fromISO(date).setZone("Africa/Porto-Novo").toLocaleString(DateTime.DATE_SHORT);
                    state.counters.push(
                        {
                            id: meter._id,
                            cNumb: meter.number,
                            cUser: meter.owner,
                            cFamily: meter.familiarName,
                            createdDate: formatDate,
                            active: false
                        }
                    )
                });
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutSearchCounter(state, text) {
        state.searchCounters = [];
        const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        axios({
            method: 'get',

            url: `/meters/search/${userId}/${text}`,

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                console.log(res.data)
                const meters = res.data
                meters.forEach(meter => {
                    const date = new Date(meter.createdAt).toISOString()
                    const formatDate = DateTime.fromISO(date).setZone("Africa/Porto-Novo").toLocaleString(DateTime.DATE_SHORT);
                    state.searchCounters.push(
                        {
                            id: meter._id,
                            cNumb: meter.number,
                            cUser: meter.owner,
                            cFamily: meter.familiarName,
                            createdDate: formatDate,
                            active: false
                        }
                    )
                });
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutSearchPostCounter(state, text) {
        state.searchPostCounters = [];
        const selected = [];

        for (const el of state.postpaidCounters) {
            const parsed = JSON.stringify(el).toLowerCase();
            if (parsed.indexOf(text.toLowerCase()) !== -1) {
                selected.push(el);
            }
        }

        state.searchPostCounters = selected;
    },

    mutCounter(state, meter) {
        const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        axios({
            method: 'post',
            url: '/meters',
            data: {
                number: meter.cNumb,
                owner: meter.cUser,
                familiarName: meter.cFamily,
                userId: userId
            },

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                console.log(res.data)
                const meter = res.data
                const date = new Date().toISOString()
                const formatDate = DateTime.fromISO(date).setZone("Africa/Porto-Novo").toLocaleString(DateTime.DATE_SHORT);
                state.counters.push(
                    {
                        id: meter._id,
                        cNumb: meter.number,
                        cUser: meter.owner,
                        cFamily: meter.familiarName,
                        createdDate: formatDate,
                        active: false
                    }
                )
                state.selectedMeter = {
                    cNumb: meter.number,
                    cUser: meter.owner,
                    cFamily: meter.familiarName
                }
            })
            .catch(err => {
                console.log(err.response)
            })
    },

    mutCounterToEdit(state, meter) {
        //const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token

        axios({
            method: 'patch',
            url: `/meters/${meter.id}`,
            data: {
                number: meter.number,
                owner: meter.user,
                familiarName: meter.familiar
            },

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                console.log(res.data)
                this.commit('mutGenerateCounters')

            })
            .catch(err => {
                console.log(err.response)
            })
    },

    mutPostpaidCounter() {
        //const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        axios({
            method: 'get',
            url: process.env.VUE_APP_API_BASE_URL + '/subscribers/user',
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                state.postpaidCounters = res.data;
                console.log(res.data);
            })
            .catch(err => {
                console.log(err.response)
            })
    },

}


export default {
    state,
    getters,
    mutations

}