/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/chevroncircle': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M13 2.438C7.167 2.438 2.437 7.167 2.437 13S7.168 23.563 13 23.563 23.563 18.832 23.563 13 18.832 2.437 13 2.437zM14.793 17.3a.812.812 0 01.193.895.813.813 0 01-1.342.253L8.77 13.574a.812.812 0 010-1.148l4.875-4.875a.813.813 0 011.15 1.148L10.492 13l4.3 4.3z" _fill="#fff"/>'
  }
})
