/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/carteban': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M1.125 13.219a1.969 1.969 0 001.969 1.969h11.812a1.969 1.969 0 001.969-1.97V7.806H1.125v5.414zm2.32-2.672A1.055 1.055 0 014.5 9.492h1.688a1.055 1.055 0 011.054 1.055v.703a1.055 1.055 0 01-1.054 1.055H4.5a1.055 1.055 0 01-1.055-1.055v-.703zm11.461-7.735H3.094a1.969 1.969 0 00-1.969 1.97v.913h15.75v-.914a1.969 1.969 0 00-1.969-1.968z" _fill="#4D4C4C"/>'
  }
})
