/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/toasterror': {
    width: 41,
    height: 40,
    viewBox: '0 0 41 40',
    data: '<path pid="0" d="M20.5 3.75C11.54 3.75 4.25 11.04 4.25 20s7.29 16.25 16.25 16.25S36.75 28.96 36.75 20 29.46 3.75 20.5 3.75zm5.884 20.366a1.25 1.25 0 01.389.89 1.25 1.25 0 01-.78 1.175 1.25 1.25 0 01-1.377-.297L20.5 21.768l-4.116 4.116a1.25 1.25 0 01-1.745-.023 1.25 1.25 0 01-.023-1.745L18.732 20l-4.116-4.116a1.25 1.25 0 011.768-1.767l4.116 4.115 4.116-4.115a1.25 1.25 0 011.745.022 1.25 1.25 0 01.023 1.745L22.268 20l4.116 4.116z" _fill="#fff"/>'
  }
})
