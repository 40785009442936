/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/eyegreen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15a3 3 0 100-6 3 3 0 000 6z" _fill="#27AE60"/><path pid="1" d="M23.008 11.184c-1.24-1.918-2.85-3.547-4.653-4.712-1.996-1.29-4.199-1.972-6.37-1.972-1.994 0-3.954.57-5.826 1.693-1.91 1.145-3.639 2.818-5.141 4.972a1.496 1.496 0 00-.03 1.666c1.238 1.937 2.831 3.569 4.607 4.718 1.999 1.295 4.15 1.951 6.389 1.951 2.19 0 4.397-.676 6.384-1.956 1.803-1.16 3.41-2.796 4.645-4.73a1.51 1.51 0 00-.005-1.63zM12 16.5a4.5 4.5 0 01-4.157-2.778A4.5 4.5 0 1116.5 12a4.505 4.505 0 01-4.5 4.5z" _fill="#27AE60"/>'
  }
})
