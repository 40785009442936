/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/calendarbig': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" d="M32.688 24.25h-3.126a.313.313 0 00-.312.313v3.125c0 .172.14.312.313.312h3.125a.313.313 0 00.312-.313v-3.125a.313.313 0 00-.313-.312zm6.25 0h-3.126a.313.313 0 00-.312.313v3.125c0 .172.14.312.313.312h3.124a.313.313 0 00.313-.313v-3.125a.313.313 0 00-.313-.312zm-6.25 6.25h-3.126a.313.313 0 00-.312.313v3.125c0 .172.14.312.313.312h3.125a.313.313 0 00.312-.313v-3.124a.313.313 0 00-.313-.313zm6.25 0h-3.126a.313.313 0 00-.312.313v3.125c0 .172.14.312.313.312h3.124a.313.313 0 00.313-.313v-3.124a.313.313 0 00-.313-.313zm-18.75 0h-3.125a.313.313 0 00-.313.313v3.125c0 .172.14.312.313.312h3.125a.313.313 0 00.312-.313v-3.124a.313.313 0 00-.313-.313zm6.25 0h-3.125a.313.313 0 00-.313.313v3.125c0 .172.14.312.313.312h3.125a.313.313 0 00.312-.313v-3.124a.313.313 0 00-.313-.313zm-6.25 6.25h-3.125a.313.313 0 00-.313.313v3.124c0 .173.14.313.313.313h3.125a.313.313 0 00.312-.313v-3.124a.313.313 0 00-.313-.313zm6.25 0h-3.125a.313.313 0 00-.313.313v3.124c0 .173.14.313.313.313h3.125a.313.313 0 00.312-.313v-3.124a.313.313 0 00-.313-.313zm6.25 0h-3.126a.313.313 0 00-.312.313v3.124c0 .173.14.313.313.313h3.125a.313.313 0 00.312-.313v-3.124a.313.313 0 00-.313-.313z" _fill="#222F5A"/><path pid="1" d="M43 13h-3.75v-2.5h-3.125V13h-16.25v-2.5H16.75V13H13a2.5 2.5 0 00-1.768.732A2.5 2.5 0 0010.5 15.5V43a2.5 2.5 0 00.732 1.768A2.5 2.5 0 0013 45.5h30a2.5 2.5 0 001.768-.732A2.5 2.5 0 0045.5 43V15.5a2.5 2.5 0 00-.732-1.768A2.5 2.5 0 0043 13zm-.938 29.063H13.939V21.75h28.124v20.313z" _fill="#222F5A"/>'
  }
})
