/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/sign': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M3.333 6.667H0V30a3.333 3.333 0 003.333 3.333h23.334V30H3.333V6.667zM30 0a3.333 3.333 0 013.333 3.333v20A3.334 3.334 0 0130 26.667H10a3.333 3.333 0 01-3.333-3.334v-20A3.333 3.333 0 0110 0h20zm-5 8.333a5 5 0 10-10 0 5 5 0 0010 0zM10 21.667v1.666h20v-1.666c0-3.334-6.667-5.167-10-5.167s-10 1.833-10 5.167z" _fill="#222F5A"/>'
  }
})
