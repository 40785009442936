/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smartphone': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0)" _fill="#222F5A"><path pid="0" d="M20 6.193v2.594h-1.841a1.298 1.298 0 010-2.594H20zm0 7.532v2.593h-1.214a1.133 1.133 0 01-.041 0 1.288 1.288 0 01-.875-.38 1.29 1.29 0 01-.38-.917c0-.346.135-.672.38-.917a1.29 1.29 0 01.875-.379H20zM.586 16.235A.586.586 0 010 15.649V9.373c0-.324.262-.586.586-.586H2.51V2.469a.586.586 0 01.586-.586 2.552 2.552 0 012.552 2.552v11.213a.586.586 0 01-.586.586H.586zm16.276-4.98c0 .715.582 1.297 1.297 1.297H20V9.96h-1.841c-.715 0-1.297.581-1.297 1.296z"/><path pid="1" d="M6.806 16.318h9.88a2.452 2.452 0 01-.368-1.297c0-.605.216-1.176.61-1.626a2.47 2.47 0 01-.364-4.022 2.463 2.463 0 01-.874-1.883 2.471 2.471 0 012.469-2.469h.586v-1.38H6.806v12.677zm3.415-8.607a1.674 1.674 0 011.191-.494h.199v-.669a.586.586 0 011.172 0v.67h.826a.586.586 0 010 1.171h-2.197a.509.509 0 00-.362.15.509.509 0 00-.15.363c0 .282.23.512.512.512h1.57a1.686 1.686 0 011.19 2.875 1.673 1.673 0 01-1.19.494h-.2v.669a.586.586 0 01-1.171 0v-.67h-.826a.586.586 0 010-1.171h2.196a.51.51 0 00.363-.15.509.509 0 00.15-.363.513.513 0 00-.513-.512h-1.569a1.686 1.686 0 01-1.19-2.875zm7.8-6.988A2.454 2.454 0 0016.276 0H9.275a2.472 2.472 0 00-2.469 2.469h11.939c0-.66-.257-1.28-.724-1.746zM6.806 17.53a2.472 2.472 0 002.469 2.47h7.001c.66 0 1.28-.257 1.746-.724a2.454 2.454 0 00.723-1.746v-.041H6.806v.041z"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
