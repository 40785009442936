<template>
  <div id="#app" class="ffamily-pro grid">
    <router-view></router-view>
    <Kfooter v-if="!landingpage"></Kfooter>
  </div>
</template>

<script>
import Kfooter from './components/partials/Kfooter';
//import axios from 'axios'
import '@/assets/svgIconsJs'
import { socket } from "./io";
export default {
  name: 'App',
  components: {
    Kfooter,
    
  },
  data(){
    return{
      dashboard: true
    }
  },

  computed: {
    landingpage(){
      return this.$route.path === '/'
    }
  },

  methods: {
    redirectToErrorPage(data){
      const transaction = {
        amount: data.amount,
        meterNumb: data.meterNumb,
        meterOwner: data.meterOwner,
        phone: data.phone,
        countryCode: data.countryCode
      }
      this.$store.commit('mutJobFailedTransact', transaction)
      const user = this.$store.getters.getAuthUser
      user.id ? this.$router.push('errorRecharge') : this.$router.push('/errorRecharge')
    },
    redirectToSuccessPage(data){
      const purchaseData = {
        counterNum: data.meterNum,
        amount: data.amount,
        codeSts: data.code,
        kwh: data.kwh,
        entretien: data.fees,
        dettes: data.debt,
        tva: data.tva,
        fraisMtn: data.transacFees,
        total: data.total,
        id: data.transacId
      }
      const user = this.$store.getters.getAuthUser
      if(user.id){
        this.$store.commit('mutDashPaySuccessData', purchaseData)
        this.$router.push('dashboard/success')
      }
      else{
        this.$store.commit('mutPaySuccessData', purchaseData)
        this.$router.push('/bigframe')
      }
    }
  },

  mounted() {
    const oldTransac = localStorage.getItem('sbeeTransaction')
    if(oldTransac) {
      socket.on(`sbee_server_not_respond${oldTransac}`, (data) => {
        console.log('sbee server not respond event: ', data);
        this.redirectToErrorPage(data)
      })

      socket.on(`job_vending_failed${oldTransac}`, (data) => {
        console.log('vending by job failed event: ', data);
        this.redirectToErrorPage(data)
      })
      
      socket.on(`job_vending_success${oldTransac}`, (data) => {
        console.log('vending by job success event: ', data);
        this.redirectToSuccessPage(data)
      })
    
      socket.on(`vending_success${oldTransac}`, (data) => {
        console.log('vending by webhook success event: ', data);
        this.redirectToSuccessPage(data)
      })
    }
  },
  metaInfo() {
    return { 
        title: "KKiaPay SBEE - Rechargez votre compteur SBEE en toute simplicité de chez vous !",
        meta: [
            { name: 'description', content:  "KKiaPay SBEE est la plateforme de dématérialisation des services de la Société Béninoise d'Energie Electrique. Elle permet aujourd’hui aux personnes physiques et ménages de recharger leur compteur électrique en quelques clics et d’accéder à l’historique de leur opération de recharge."},
            /* { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
            { property: 'og:site_name', content: 'Epiloge'},
            {property: 'og:type', content: 'website'},    
            {name: 'robots', content: 'index,follow'}  */
        ]
    }
  }
}
</script>

<style >
.ffamily-pro {
    font-family: "Source Sans Pro"
}

</style>
