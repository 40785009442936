/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/flash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="12" _fill="#E6ECF4"/><path pid="1" d="M17.5 10.5H13l1-6-7.5 9H11l-1 6 7.5-9z" _fill="#222F5A"/><path pid="2" d="M14 4.5l-7.5 9H11l2-3 1-6z" _fill="#F11C33"/>'
  }
})
