/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/phonemomo': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.5.5h-5A1.5 1.5 0 004 2v12a1.5 1.5 0 001.5 1.5h5A1.5 1.5 0 0012 14V2A1.5 1.5 0 0010.5.5z" _stroke="#222F5A" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M5.5.5h.75a.25.25 0 01.25.25.5.5 0 00.5.5h2a.5.5 0 00.5-.5.25.25 0 01.25-.25h.75" _stroke="#222F5A" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
