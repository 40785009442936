//import axios from 'axios'
const state = {
  counterBill: {
    subscriberName: '',
    reference: '',
    bills: '',
    totalAmount: '',
  },
  paidBillsData: {
    amount: 0,
    fees: 0,
    subscriber: "",
    bills: [
      {
        amountToBePaid: 0,
        invoiceNumber: "",
        invoicePeriod: 0,
        status: "",
        subscriberReference: "",
        transaction: ""
      }
    ]
  } ,
  /* __v: 

_id: 

amount: 2001

bills: [
  {amountToBePaid: 2001

invoiceNumber: "FE551011200054"

invoicePeriod: 201511

status: "unpaid"

subscriberReference: "110100115071HA"

transaction: "61b6ec288d1237a8dc7c88a3"}
]

dateFromOperatorToSystem: 20211213

dateOfSettlementOperator: 20211213

dateOfTransaction: 20211213

dateSentToSbee: 20211213

fees: 38

nameOfTheOperator: "mtn-benin"

providerCommonName: "mtn-benin"

status: "success"

subscriber: "61b60a58b24bdb0c4f7a74cd"

timeFromOperatorToSystem: "072851"

timeOfSending: "072900"

timeOfSettlementOperator: "072900"

timeOfTransaction: "072900" */
};

const getters = {
  getBillProcessFirstPur: state => {
    return state.counterBill
  },
  getPaidBillsData: state => {
    return state.paidBillsData
},
}

const mutations = {
  mutBillProcessFirstPur(state, data) {
    state.counterBill = data
  },
  mutPaidBillsData: (state, purchaseData) => {
    state.paidBillsData = purchaseData
},
}


export default{
    state,
    getters,
    mutations

}