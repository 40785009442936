/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'amburger': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" _fill="#222F5A" d="M0 0h24v4H0zm0 8h24v4H0zm0 8h24v4H0z"/>'
  }
})
