import axios from 'axios'
const state = {
    user: {
        token: null,
        umId: null,
        id: null,
        lastName: null,
        firstName: null,
        email: null,
        phone: null,
        countryCode: null
    },
};

const getters = {
    getAuthUser(state){
        return state.user
    }
}

const mutations = {
    mutAuthUser(state, userData){
        state.user = {
            token: userData.token,
            umId: userData.umId,
            id: userData.id,
            lastName: userData.lastName,
            firstName: userData.firstName,
            email: userData.email,
            phone: userData.phone,
            countryCode: userData.countryCode,
        }
    }
}

const actions = {
    tryLogin({commit}) {
        const token = localStorage.getItem('token')
            axios.post(`/users/check`, {
                token: token
            })
            .then(res =>{
                console.log(res)
                const userData = res.data
                commit('mutAuthUser', userData)
                
            })
            .catch(err =>{
                console.log(err)
            })
    },
    logout({commit}) {
        localStorage.removeItem('token')
        commit('mutAuthUser', null)
    }
}

export default{
    state,
    getters,
    mutations,
    actions

}