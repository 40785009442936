import axios from 'axios'
import { store } from '../store'
import { DateTime } from "luxon";

const state = {
    purchases: [],
    bills: [],
    searchPurchases: [],
    searchBills: [],
    trashPurchase: false,
    sendPurchase: false,
    purchaseId: null,
    transacId: null,
    byMail: true,
    counterPurchases: [],
    totalRecharge: null,
    meterTotalRecharge: null,
    numbOfPages: null,
    numbOfBill: 0,
    meterNumbOfPages: null,
    counterToSeenCreatedDate: '_ _ _',
    toast: {
        show: false,
        message: "",
        icon: 'IconSuccess',
        color: 'bg-bgk-3'
    }
};

const getters = {
    getPurchases: state => {
        return state.purchases
    },
    getBills: state => {
        return state.bills
    },
    getSearchBills: state => state.searchBills,
    getSearchPurchases: state => {
        return state.searchPurchases
    },
    getNumbOfPages: state => {
        return state.numbOfPages
    },
    getNumbOfBill: state => {
        return state.numbOfBill
    },
    getMeterNumbOfPages: state => {
        return state.meterNumbOfPages
    },
    getTrashPurchase: state => {
        return state.trashPurchase
    },
    getSendPurchase: state => {
        return state.sendPurchase
    },
    getPurchaseId: state => {
        return state.purchaseId
    },

    getCounterPurchases: state => {
        return state.counterPurchases
    },
    getTotalRecharge: state => {
        return state.totalRecharge
    },
    getMeterTotalRecharge: state => {
        return state.meterTotalRecharge
    },
    getCounterToSeenCreatedDate: state => {
        return state.counterToSeenCreatedDate
    },

    getToast(state) {
        return state.toast
    }
}

const mutations = {
    mutCounterToSeenCreatedDate(state, createdDate) {
        state.counterToSeenCreatedDate = createdDate
    },
    mutTransacId(state, id) {
        state.transacId = id
    },
    mutByMail(state, bool) {
        state.byMail = bool
    },
    mutTrashPurchase(state, bool) {
        state.trashPurchase = bool
    },
    mutSendPurchase(state, bool) {
        state.sendPurchase = bool
    },
    mutPurchaseId(state, id) {
        state.purchaseId = id
    },
    mutPurchaseUpdate(state) {
        state.purchases = state.purchases.filter(
            purchase => {
                return purchase.id != state.purchaseId;
            }
        );
    },
    mutSeeCounterPurchases(state, meter) {
        const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        axios({
            method: 'get',

            url: `/bills/meter/${userId}/${meter.numb}/${meter.page}`,

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                console.log(res.data)
                state.counterPurchases = [];
                const meterPurchases = res.data.billsPerPage
                state.meterNumbOfPages = res.data.numbOfPages
                meterPurchases.forEach(purchase => {
                    const date = new Date(purchase.createdAt).toISOString()
                    const formatDate = DateTime.fromISO(date).setZone("Africa/Porto-Novo").toLocaleString(DateTime.DATETIME_MED);
                    state.counterPurchases.push(
                        {
                            id: purchase._id,
                            counterId: purchase.meterId,
                            counter: purchase.meterNumb,
                            cUser: purchase.meterOwner,
                            cFamily: purchase.meterFamiliarName,
                            codeSts: purchase.code,
                            amount: purchase.amount,
                            date: formatDate,
                            transacId: purchase.transacId,
                            active: false
                        }
                    )
                });
                state.meterTotalRecharge = res.data.totalRechargeForMeter

            })
            .catch(err => {
                console.log(err.response)
            })

    },
    mutPurchase(state, data) {
        state.purchases = [];
        const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        let page = data.page
        let startDate = data.startDate
        let endDate = data.endDate
        axios({
            method: 'get',

            url: `/bills/user/${userId}`,

            headers: {
                'Authorization': `Bearer ${token}`,
            },
            params: {
                page,
                startDate,
                endDate
            }
        })
            .then(res => {
                console.log(res)
                const purchases = res.data.billsPerPage
                state.numbOfPages = res.data.numbOfPages
                state.numbOfBill = res.data.numbOfBill
                purchases.forEach(purchase => {
                    const date = new Date(purchase.createdAt).toISOString()
                    const formatDate = DateTime.fromISO(date).setZone("Africa/Porto-Novo").toLocaleString(DateTime.DATETIME_MED);
                    state.purchases.push(
                        {
                            id: purchase._id,
                            counter: purchase.meterNumb,
                            cUser: purchase.meterOwner,
                            codeSts: purchase.code,
                            amount: purchase.amount,
                            date: formatDate,
                            transacId: purchase.transacId,
                            active: false
                        }
                    )
                });
                state.totalRecharge = res.data.totalRechargeForUser
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutBills(state, data = { page: 1 }) {
        state.bills = [];
        const token = store.getters.getAuthUser.token
        let page = data.page;
        let startDate = data.startDate ? data.startDate : null;
        let endDate = data.endDate ? data.endDate : null;
        const params = `page=${page}${startDate ? ('&startDate=' + startDate) : ''}${endDate ? ('&endDate=' + endDate) : ''}`
        axios
            .get(process.env.VUE_APP_API_BASE_URL + '/transactions?' + params, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then(res => {
                console.log(res.data);
                state.numbOfPages = Math.ceil(res.data.pagination.total / res.data.pagination.perPage);
                state.bills = [...res.data.data.map(it => ({ ...it, active: false }))];
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutSearchBills(state, text) {
        state.searchBills = [];
        const token = store.getters.getAuthUser.token
        axios
            .get(process.env.VUE_APP_API_BASE_URL + '/transactions?search=' + text, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(res => {
                state.numbOfPages = Math.ceil(res.data.pagination.total / res.data.pagination.perPage);
                state.searchBills = [...res.data.data.map(it => ({ ...it, active: false }))];
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutSearchPurchase(state, text) {
        state.searchPurchases = [];
        const userId = store.getters.getAuthUser.id
        const token = store.getters.getAuthUser.token
        axios({
            method: 'get',

            url: `/bills/search/${userId}/${text}`,

            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(res => {
                console.log(res.data)
                const purchases = res.data
                purchases.forEach(purchase => {
                    const date = new Date(purchase.createdAt).toISOString()
                    const formatDate = DateTime.fromISO(date).setZone("Africa/Porto-Novo").toLocaleString(DateTime.DATETIME_MED);
                    state.searchPurchases.push(
                        {
                            id: purchase._id,
                            counter: purchase.meterNumb,
                            cUser: purchase.meterOwner,
                            codeSts: purchase.code,
                            amount: purchase.amount,
                            date: formatDate,
                            transacId: purchase.transacId,
                            active: false
                        }
                    )
                });
            })
            .catch(err => {
                console.log(err.response)
            })

    },

    mutSendBill(state, channel) {
        if (state.byMail) {
            axios.post(`/bills/sendbyemail/${state.transacId}`, {
                email: channel.email
            })
                .then(res => {
                    console.log(res)
                    state.toast = {
                        show: true,
                        message: 'Reçu envoyée avec success',
                        icon: 'IconSuccess',
                        color: 'bg-bgk-3'
                    }
                })
                .catch(err => {
                    state.toast = {
                        show: true,
                        message: "Échec de l'envoie du reçu",
                        icon: 'IconError',
                        color: 'bg-tex-2'
                    }
                    console.log(err)
                })
        }
        else {
            axios.post(`/bills/sendbysms/${state.transacId}`, {
                phoneNumber: channel.smsTel,
                countryCode: channel.countryCode
            })
                .then(res => {
                    console.log(res)
                    state.toast = {
                        show: true,
                        message: 'Reçu envoyée avec success',
                        icon: 'IconSuccess',
                        color: 'bg-bgk-3'
                    }
                })
                .catch(err => {
                    console.log(err)
                    state.toast = {
                        show: true,
                        message: "Échec de l'envoie du reçu",
                        icon: 'IconError',
                        color: 'bg-tex-2'
                    }
                })
        }
    },

    setToast(state, toast) {
        state.toast = toast
    }
}
const actions = {
    sendBill({ commit }, channel) {
        commit('mutSendBill', channel)
    },

    downloadBillRequest(_, transacId) {

        return new Promise((resolve, reject) => {
            axios.post(`bills/download/${transacId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => { reject(error.response) })
        })
    },

    sendBillRequest({ state }, channel) {

        return new Promise((resolve, reject) => {
            if (state.byMail) {
                axios.post(`/bills/sendbyemail/${state.transacId}`, {
                    email: channel.email
                })
                    .then(res => {
                        console.log(res)

                        resolve(res.data)
                    })
                    .catch(err => {

                        console.log(err)

                        reject(err.response)
                    })
            }
            else {
                axios.post(`/bills/sendbysms/${state.transacId}`, {
                    phoneNumber: channel.smsTel,
                    countryCode: channel.countryCode
                })
                    .then(res => {
                        console.log(res)

                        resolve(res.data)
                    })
                    .catch(err => {
                        console.log(err)

                        reject(err.response)
                    })
            }
        })
    },

    getAmountWithFees(_, amount) {

        return new Promise((resolve, reject) => {
            axios.post(`transaction/amount-with-fee`, { amount })
                .then(response => {
                    resolve(response)
                })
                .catch(error => { reject(error.response) })
        })
    },

    findReload(_, data) {
        return new Promise((resolve, reject) => {
            axios.post(`transaction/bill`, data)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => { reject(error.response) })
        })
    }
}


export default {
    state,
    getters,
    mutations,
    actions

}