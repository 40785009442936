/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/toascheck': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<circle pid="0" cx="14" cy="14" r="14" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20.296 8.789a.569.569 0 01.053.802l-7.962 9.1a.569.569 0 01-.83.028l-3.413-3.413a.569.569 0 01.804-.804l2.983 2.983 7.562-8.643a.569.569 0 01.803-.053z" _fill="#27AE60" _stroke="#27AE60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
