/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/smallmail': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M14.24 2.5H3.74a1.752 1.752 0 00-1.75 1.75v7.5a1.752 1.752 0 001.75 1.75h10.5a1.752 1.752 0 001.75-1.75v-7.5a1.752 1.752 0 00-1.75-1.75zm-.442 2.895l-4.5 3.5a.5.5 0 01-.614 0l-4.5-3.5a.5.5 0 01-.094-.706.5.5 0 01.708-.084L8.99 7.867l4.193-3.262a.5.5 0 01.696.092.5.5 0 01-.082.698z" _fill="#222F5A"/>'
  }
})
