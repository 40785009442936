/* eslint-disable */
require('./Accueil')
require('./background2lg')
require('./battery')
require('./calendar')
require('./calendarbig')
require('./carteban')
require('./chevroncalendar')
require('./chevroncircle')
require('./chevronDate')
require('./chevrondown')
require('./chevronforward')
require('./close')
require('./compteurlogo')
require('./comptimg')
require('./datatablmb')
require('./dollar')
require('./downloadblue')
require('./edit')
require('./eyegreen')
require('./kkiapay2')
require('./kkiapaybill')
require('./lock')
require('./logout')
require('./menumobil')
require('./mescompteur')
require('./mesfactur')
require('./momocircle')
require('./moyenspaiment')
require('./paymentcard')
require('./phonemomo')
require('./profiluser')
require('./rechargecompteu')
require('./refresh')
require('./save')
require('./search')
require('./sendgreen')
require('./trash')
require('./visacircle')
