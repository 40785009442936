/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/smallphone': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M11 .5H6A1.5 1.5 0 004.5 2v12A1.5 1.5 0 006 15.5h5a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0011 .5z" _stroke="#000" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M6 .5h.75A.25.25 0 017 .75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5.25.25 0 01.25-.25H11" _stroke="#000" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
