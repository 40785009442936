/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/download': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M14.688 6.25h-4.063v5.991l2.058-2.058a.625.625 0 01.873.011.625.625 0 01.01.873l-3.124 3.125a.625.625 0 01-.884 0l-3.125-3.125a.625.625 0 01.884-.884l2.058 2.058V6.25H5.312a2.19 2.19 0 00-2.187 2.188v8.124a2.19 2.19 0 002.188 2.188h9.375a2.19 2.19 0 002.187-2.188V8.438a2.19 2.19 0 00-2.188-2.187zm-4.063-4.375A.625.625 0 0010 1.25a.625.625 0 00-.625.625V6.25h1.25V1.875z" _fill="#fff"/>'
  }
})
