/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/edit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.126 12.216a.78.78 0 00-.784.776v6.416c0 .58-.484 1.04-1.065 1.04H4.643c-.586 0-1.075-.46-1.075-1.04V5.55c0-.58.49-1.027 1.075-1.027h5.286a.78.78 0 00.784-.776.78.78 0 00-.784-.775H4.643C3.195 2.972 2 4.117 2 5.55v13.858C2 20.841 3.195 22 4.643 22h13.634c1.448 0 2.633-1.155 2.633-2.592v-6.416a.782.782 0 00-.784-.775z" _fill="#0965F6"/><path pid="1" d="M20.09 2.643A2.18 2.18 0 0018.552 2a2.16 2.16 0 00-1.54.63L9.965 9.602c-.262.26-.452.584-.553.935l-1.227 4.299a.768.768 0 00.208.767.795.795 0 00.548.219.66.66 0 00.231-.037l4.225-1.287c.337-.1.646-.287.895-.534l7.075-7c.84-.83.844-2.18.014-3.02l-1.292-1.3zM16.583 5.25l2.13 2.154-.52.515-2.131-2.154.521-.515zm-3.404 7.625a.57.57 0 01-.249.146l-2.85.867.834-2.93a.65.65 0 01.153-.26l3.879-3.837 2.13 2.153-3.897 3.86zm7.08-7.005l-.438.434-2.131-2.154.424-.415a.588.588 0 01.425-.169h.004c.162 0 .314.06.425.174l1.291 1.3a.585.585 0 010 .83z" _fill="#0965F6"/>'
  }
})
