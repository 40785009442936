/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/calendar': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.375 3.375c-.621 0-1.125.504-1.125 1.125v10.125c0 .621.504 1.125 1.125 1.125h11.25c.621 0 1.125-.504 1.125-1.125V4.5c0-.621-.504-1.125-1.125-1.125H3.375zM1.125 4.5a2.25 2.25 0 012.25-2.25h11.25a2.25 2.25 0 012.25 2.25v10.125a2.25 2.25 0 01-2.25 2.25H3.375a2.25 2.25 0 01-2.25-2.25V4.5z" _fill="#000"/><path pid="1" d="M13.986 2.813H4.014c-1.283 0-2.327 1.06-2.327 2.362v2.138h.563c0-.563.563-1.125 1.125-1.125h11.25c.563 0 1.125.562 1.125 1.125h.563V5.175c0-1.303-1.044-2.362-2.327-2.362z" _fill="#000"/><path pid="2" d="M10.406 9a.844.844 0 100-1.688.844.844 0 000 1.688zm2.813 0a.844.844 0 100-1.688.844.844 0 000 1.688zm-2.813 2.813a.844.844 0 100-1.688.844.844 0 000 1.688zm2.813 0a.844.844 0 100-1.688.844.844 0 000 1.688zm-8.438 0a.844.844 0 100-1.688.844.844 0 000 1.688zm2.813 0a.844.844 0 100-1.688.844.844 0 000 1.688zm-2.813 2.812a.844.844 0 100-1.688.844.844 0 000 1.688zm2.813 0a.844.844 0 100-1.688.844.844 0 000 1.688zm2.812 0a.844.844 0 100-1.688.844.844 0 000 1.688z" _fill="#000"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 1.125c.31 0 .563.252.563.563v1.125a.563.563 0 01-1.125 0V1.687c0-.31.251-.562.562-.562zm9 0c.31 0 .563.252.563.563v1.125a.562.562 0 11-1.126 0V1.687c0-.31.252-.562.563-.562z" _fill="#000"/>'
  }
})
