/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'battery': {
    width: 42,
    height: 40,
    viewBox: '0 0 42 40',
    data: '<path pid="0" d="M30.708 22.723H9.064a.809.809 0 00-.808.809v11.797c0 .447.362.809.808.809h21.644a.809.809 0 00.809-.809V23.532a.809.809 0 00-.809-.809zm-10.68 7.496h-2.975l2.167-3.704v1.47c0 .447.362.81.809.81h2.92l-2.112 3.575v-1.342a.809.809 0 00-.809-.809zm-5.083.4a.808.808 0 00.698 1.217h3.577v2.685h-5.743V24.34h5.142l-3.674 6.278zm-5.072 3.902V24.34h1.987v10.18H9.873zm20.027 0h-8.454l3.617-6.124a.809.809 0 00-.697-1.22h-3.529v-2.836H29.9v10.18z" _fill="#4661B9"/><path pid="1" d="M34.072 14.602H24.064a.809.809 0 000 1.617h10.008a5.87 5.87 0 015.863 5.863v.677a5.848 5.848 0 01-4.763 5.76v-5.69a3.763 3.763 0 00-3.76-3.76H8.109a3.763 3.763 0 00-3.76 3.76v2.413H1.815a1.62 1.62 0 00-1.617 1.617v5.458a1.62 1.62 0 001.617 1.617h2.535v2.1a3.763 3.763 0 003.759 3.76h23.305a3.763 3.763 0 003.759-3.76V30.16a7.462 7.462 0 006.38-7.4v-.678c0-4.125-3.355-7.48-7.48-7.48zM1.814 32.317v-5.458h2.535v5.458H1.814zm31.74 3.718c0 1.18-.96 2.141-2.141 2.141H8.108c-1.181 0-2.142-.96-2.142-2.142V22.828c0-1.18.96-2.142 2.142-2.142h23.305c1.18 0 2.142.961 2.142 2.142v13.207z" _fill="#4661B9"/><path pid="2" d="M40.744 8.242h-3.408v-4.15h3.408a.809.809 0 000-1.617h-3.408V.81A.809.809 0 0036.527 0h-5.398c-1.88 0-3.41 1.53-3.41 3.41v1.888H5.609c-3.01 0-5.46 2.45-5.46 5.46 0 3.011 2.45 5.46 5.46 5.46h11.336a.809.809 0 000-1.617H5.61a3.847 3.847 0 01-3.843-3.843A3.847 3.847 0 015.61 6.915h22.109v2.009c0 1.88 1.53 3.41 3.41 3.41h5.398a.809.809 0 00.809-.808V9.86h3.408a.809.809 0 000-1.617zm-5.026 2.475H31.13a1.795 1.795 0 01-1.793-1.793V3.411c0-.99.804-1.794 1.793-1.794h4.59v9.1z" _fill="#4661B9"/><path pid="3" d="M32.749 3.699a.809.809 0 00-.81.808v3.18a.809.809 0 001.618 0v-3.18a.809.809 0 00-.808-.808zm-12.82 12.16c.405.601 1.363.387 1.473-.33.11-.723-.763-1.203-1.319-.739a.818.818 0 00-.153 1.07z" _fill="#4661B9"/>'
  }
})
