/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/chevroncalendar': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M1.313 4.455l.969-.955L7 8.162 11.729 3.5l.959.945L7.479 9.58 7 10.061l-.48-.482-5.207-5.125z" _fill="#000"/>'
  }
})
