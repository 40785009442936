/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'closeModal': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.72 6.22a.75.75 0 011.06 0l10.5 10.5a.75.75 0 010 1.06.75.75 0 01-1.06 0L6.72 7.28a.75.75 0 010-1.06z" _fill="#333"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18.28 6.22a.75.75 0 010 1.06l-10.5 10.5a.75.75 0 01-1.06-1.06l10.5-10.5a.75.75 0 011.06 0z" _fill="#333"/>'
  }
})
