
const KJobFailedResponse = () => import('./pages/bodypages/KJobFailedResponse')
const KsFirstPurchase = () => import('./pages/bodypages/KsFirstPurchase')
const KsFirstPurchaseSuccess = () => import('./pages/bodypages/KsFirstPurchaseSuccess')
const KdPayInfo = () => import('./pages/bodypages/KdPayInfo')
const KsInfo =() => import ('./pages/bodypages/KsInfo')
const KsFirstPurchaseBillSuccess = () => import('./pages/bodypages/KsFirstPurchaseBillSuccess')
const KsPurchaseBillSuccess = () => import('./pages/bodypages/KsPurchaseBillSuccess')
const KsRegister = () => import('./pages/bodypages/KsRegister')
const KsRegisterStep1 = () => import('./pages/bodypages/KsRegisterStep1')
const KsLogin = () => import('./pages/bodypages/KsLogin')
const KsPasswordReset = () => import('./pages/bodypages/KsPasswordReset')
const Kbody = () => import('./components/partials/Kbody')
const Kdashboard = () => import('./components/partials/Kdashboard')
const KdAllPurchases = () => import('./pages/dashboardpages/KdAllPurchases')
const KdMakePurchase = () => import('./pages/dashboardpages/KdMakePurchase')
const KdMakePay = () => import('./pages/dashboardpages/KdMakePay')
const KdPurchaseSuccess = () => import('./pages/dashboardpages/KdPurchaseSuccess')
const KdCounters = () => import('./pages/dashboardpages/KdCounters')
const KdOperation = () => import('./pages/dashboardpages/KdOperation')
const KdCounterSeen = () => import('./pages/dashboardpages/KdCounterSeen')
const find = () => import('./pages/bodypages/find')
const KdAccount = () => import('./pages/dashboardpages/KdAccount')
const KdJobFailedResponse = () => import('./pages/dashboardpages/KdJobFailedResponse')
const KLandingPage = () => import('./pages/bodypages/LandingPage')
const KLandingPage2 = () => import('./pages/bodypages/LandingPage2')
//const Foo = () => import('')

import { store } from './store/store'

const localEmail = localStorage.getItem('email');
const localPhone = localStorage.getItem('phone');


export const routes = [
    {
        path: '/', name: 'landingpage', component: KLandingPage
    },
    {
        path: '/find', name: 'find', component: find
    },
    { path: '/payInfo', component: KdPayInfo },

    {
        path: '/land', name: 'landingpage2', component: KLandingPage2
    },

    {
        path: '/recharges', component: Kbody,


        children: [
            { path: '', component: KsFirstPurchase },
            {
                path: 'errorRecharge',
                meta: { socketRoute: true },
                component: KJobFailedResponse,
                beforeEnter: (to, from, next) => {
                    if (store.getters.getJobFailedTransact.amount) {
                        next()
                    }
                    else {
                        next('/recharges')
                    }
                }
            },
            {
                path: 'bigframe',
                meta: { socketRoute: true },
                component: KsFirstPurchaseSuccess,
                beforeEnter: (to, from, next) => {
                    if (store.getters.getPaySuccessData.total) {
                        next()
                    }
                    else {
                        next('/recharges')
                    } 
                }
            },
            {
                path: 'billPaySuccess',
                meta: { socketRoute: true },
                component: KsFirstPurchaseBillSuccess,
                beforeEnter(to, from , next){
                    console.log(to.query,'query');
                      if (to.query.id) {
                        next();
                  
                      }
                      else{
                          next('/');
                      }
                  },
                
            },
            {
                path:'getInfo/:info?',
                component:KsInfo,
                beforeEnter: (to, from, next) => {
                    //const info = to.params.info;
                    const info = to.query.info;
                    if (info.indexOf('@') !== -1) {
                        store.getters.getUser.email = info
                        console.log(store.getters.getUser.email,'rrrrrrrrr');
                    next('/recharges/signframe')

                    } else {
                        store.getters.getUser.phone =info
                    next('/recharges/signframe')

                    }
                    next()
                   
                }
            },
            {
                path: 'signframe',
                component: KsRegister,
                beforeEnter: (to, from, next) => {
                    if ((store.getters.getUser.email != null) || (store.getters.getUser.phone != null)) {
                        next()
                    }
                    else {
                        if (localEmail) {
                            store.state.userSign.email = localEmail;
                            localStorage.removeItem('email');
                            next();
                        }
                        if (localPhone) {
                            store.state.userSign.phone = localPhone;
                            localStorage.removeItem('phone');
                            next();
                        }
                        next('/recharges')
                    }
                }
            },
            { path: 'signsuccess', component: KsLogin },
            { path: 'register', component: KsRegisterStep1 },
            { path: 'resetpwd', component: KsPasswordReset },
        ]

    },
    {
        path: '/dashboard', component: Kdashboard,
        meta: { requiresAuth: true },

        children: [
            { path: '', component: KdAllPurchases },
            {
                path: 'errorRecharge',
                meta: { socketRoute: true },
                component: KdJobFailedResponse,
                beforeEnter: (to, from, next) => {
                    if (store.getters.getJobFailedTransact.amount) {
                        next()
                    }
                    else {
                        next('/dashboard')
                    }
                }
            },
            { path: 'recharge', component: KdMakePurchase },
            { path: 'pay', component: KdMakePay },
            {
                path: 'success',
                meta: { socketRoute: true },
                component: KdPurchaseSuccess,
                beforeEnter: (to, from, next) => {
                    if (store.getters.getDashPaySuccessData.total) {
                        next()
                    }
                    else {
                        next('/dashboard')
                    }
                }
            },
            { path: 'counters', component: KdCounters },

            { path: 'bills', component: KdOperation },
            {
                path: 'counterseen',
                component: KdCounterSeen,
                beforeEnter: (to, from, next) => {
                    if (store.getters.getMeterTotalRecharge != null) {
                        next()
                    }
                    else {
                        next('/dashboard/counters')
                    }
                }
            },
            { path: 'account', component: KdAccount },
            {
                path: 'billPaySuccessPay',
                meta: { socketRoute: true },
                component: KsPurchaseBillSuccess,
                beforeEnter: (to, from, next) => {
                    if (store.getters.getPaidBillsData) {
                        next()
                    }
                    else {
                        next('/dashboard')
                    }
                }
            },
        ],
    },
    { path: '*', redirect: '/' }

]