/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/copy': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M14.888 0h-8c-.882 0-1.6.718-1.6 1.6v3.2h-3.2c-.882 0-1.6.718-1.6 1.6v8c0 .882.718 1.6 1.6 1.6h8c.883 0 1.6-.718 1.6-1.6v-3.2h3.2c.883 0 1.6-.718 1.6-1.6v-8c0-.882-.717-1.6-1.6-1.6zm-12.8 14.4v-8h8l.002 8H2.088zm12.8-4.8h-3.2V6.4c0-.882-.717-1.6-1.6-1.6h-3.2V1.6h8v8z" _fill="#4D4C4C"/><path pid="1" d="M3.688 8h4.8v1.6h-4.8V8zm0 3.2h4.8v1.6h-4.8v-1.6z" _fill="#4D4C4C"/>'
  }
})
