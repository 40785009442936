/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/Accueil': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.5 6.625V14a.5.5 0 00.5.5h3v-4.25a.75.75 0 01.75-.75h2.5a.75.75 0 01.75.75v4.25h3a.5.5 0 00.5-.5V6.625" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M15 8L8.34 1.625c-.156-.165-.521-.167-.68 0L1 8m11.5-2.406V2H11v2.156" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
