/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/eye': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" _fill="#A5BCE0"/><path pid="1" d="M19.173 9.32C18.14 7.722 16.8 6.364 15.296 5.393c-1.663-1.075-3.5-1.643-5.31-1.643-1.66 0-3.294.475-4.854 1.41C3.542 6.115 2.1 7.51.848 9.305a1.247 1.247 0 00-.025 1.388c1.032 1.615 2.36 2.974 3.839 3.932 1.666 1.08 3.459 1.626 5.324 1.626 1.825 0 3.665-.564 5.32-1.63 1.503-.967 2.841-2.33 3.871-3.942a1.257 1.257 0 00-.004-1.358zM10 13.75a3.75 3.75 0 01-3.465-2.315 3.75 3.75 0 012.733-5.113A3.75 3.75 0 0113.75 10 3.754 3.754 0 0110 13.75z" _fill="#A5BCE0"/>'
  }
})
