/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'login': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M18 24.354a4.34 4.34 0 012.667-4v-.333c0-.81.195-1.57.521-2.26a6.303 6.303 0 00-3.521-1.074H6.333A6.34 6.34 0 000 23.021v4.667a1 1 0 001 1h17v-4.334zm.667-17a6.667 6.667 0 11-13.334 0 6.667 6.667 0 0113.334 0zm11 14.666h-7.334A2.336 2.336 0 0020 24.354v4.667a2.336 2.336 0 002.333 2.333h7.334A2.336 2.336 0 0032 29.021v-4.667a2.336 2.336 0 00-2.333-2.333z" _fill="#222F5A"/><path pid="1" d="M26 16.688a3.337 3.337 0 00-3.333 3.333v2h2v-2a1.335 1.335 0 012.666 0v2h2v-2A3.337 3.337 0 0026 16.688z" _fill="#222F5A"/>'
  }
})
