/* eslint-disable */
require('./beninflag')
require('./bigcheck')
require('./billicon')
require('./cash')
require('./check')
require('./codeSTS')
require('./codestsmb')
require('./copy')
require('./dette')
require('./download')
require('./errorIcon')
require('./eye')
require('./eyeoff')
require('./flash')
require('./id')
require('./mail')
require('./MTNmomo')
require('./offline')
require('./online')
require('./person')
require('./receiveBill')
require('./receiveBillBig')
require('./rechargelady')
require('./redarrow')
require('./redarrowreverse')
require('./send')
require('./sendBill')
require('./share')
require('./sign')
require('./smallmail')
require('./smallphone')
require('./smallsms')
require('./toascheck')
require('./toasterror')
require('./tool')
