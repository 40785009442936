/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/bigcheck': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<circle pid="0" opacity=".2" cx="40" cy="40" r="40" _fill="#27AE60"/><circle pid="1" cx="40" cy="40" r="32.421" _fill="#27AE60"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M58.372 25.68c.7.612.771 1.676.158 2.376L34.952 55.004a1.684 1.684 0 01-2.459.082L22.388 44.98a1.684 1.684 0 112.382-2.382l8.832 8.833 22.394-25.593c.612-.7 1.676-.77 2.376-.158z" _fill="#fff" _stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
