/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/share': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM12 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM5.308 8.735l5.385 3.03m0-7.53l-5.385 3.03" _stroke="#4D4C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
