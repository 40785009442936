/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/sendgreen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.193 10.46l-.007-.002L3.39 3.076a.982.982 0 00-.926.09A1.027 1.027 0 002 4.024v4.721a1 1 0 00.813.982l9.707 1.795a.166.166 0 010 .327l-9.706 1.795a1 1 0 00-.814.982v4.721a.982.982 0 00.441.82.997.997 0 00.551.167 1.024 1.024 0 00.397-.08l17.796-7.34.008-.003a1.334 1.334 0 00.587-1.959 1.332 1.332 0 00-.587-.491z" _fill="#27AE60"/>'
  }
})
