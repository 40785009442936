/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/battery': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.25 15.108V8.892A1.394 1.394 0 013.642 7.5h6.443L11.228 6H3.643A2.895 2.895 0 00.75 8.892v6.216A2.895 2.895 0 003.642 18h4.54c-.015-.114-.01-.23.012-.343l.007-.035.01-.035.305-1.087H3.642a1.394 1.394 0 01-1.392-1.392z" _fill="#0965F6"/><path pid="1" d="M18.108 6h-4.624c.014.112.01.226-.013.338l-.007.034-.01.034-.306 1.094h4.96A1.394 1.394 0 0119.5 8.892v6.216a1.394 1.394 0 01-1.392 1.392h-6.53L10.433 18h7.675A2.896 2.896 0 0021 15.108V8.892A2.896 2.896 0 0018.108 6zM7.624 13.789a1.143 1.143 0 01-1.01-.61 1.172 1.172 0 01.066-1.21l.015-.022.015-.02 2.554-3.349h-5.06a.938.938 0 00-.937.938v4.968a.938.938 0 00.937.938h4.614l.458-1.634H7.624z" _fill="#2D7BF7"/><path pid="2" d="M12.94 13.167l1.27-1.664a.243.243 0 00-.055-.325.19.19 0 00-.114-.035h-2.888l.568-2.026.152-.539.29-1.038.024-.087.366-1.3a.171.171 0 00.002-.027V6.12c0-.002 0-.012-.002-.018-.003-.006 0-.004 0-.006a.106.106 0 00-.02-.04l-.005-.005a.134.134 0 00-.011-.01l-.005-.004a.093.093 0 00-.038-.018h-.048a.086.086 0 00-.02.007l-.006.003-.014.01-.005.005a.11.11 0 00-.017.02L11.52 7.17l-.616.807-.462.601-2.986 3.917a.268.268 0 00-.037.088.22.22 0 00-.004.06.228.228 0 00.094.175l.025.014c.028.014.058.02.089.02h2.888l-1.398 4.989a.17.17 0 000 .026v.007l.003.018v.007a.105.105 0 00.008.02.182.182 0 00.012.018l.005.005.011.011a.1.1 0 00.042.021h.029a.095.095 0 00.04-.01l.007-.003.014-.01.005-.004a.108.108 0 00.017-.02l1.093-1.427.822-1.078 1.72-2.255z" _fill="#2D7BF7"/><path pid="3" d="M14.977 12.044l-.022.028-2.556 3.35h5.147a.938.938 0 00.937-.938V9.516a.938.938 0 00-.937-.938h-4.7l-.457 1.628h1.652a1.142 1.142 0 011.006.599A1.183 1.183 0 0115 12.014l-.023.03zM22.5 9.5a.75.75 0 00-.75.75v3.5a.75.75 0 101.5 0v-3.5a.75.75 0 00-.75-.75z" _fill="#2D7BF7"/>'
  }
})
