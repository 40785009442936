/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/person': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<path pid="0" d="M13.975 2.523c-.76-.821-1.822-1.273-2.993-1.273-1.179 0-2.244.45-3 1.265-.765.824-1.138 1.945-1.05 3.155.173 2.388 1.99 4.33 4.05 4.33 2.06 0 3.873-1.942 4.05-4.329.088-1.2-.287-2.317-1.057-3.148zm3.882 16.227H4.107a1.212 1.212 0 01-.946-.435 1.379 1.379 0 01-.28-1.137c.33-1.829 1.359-3.365 2.976-4.444 1.436-.957 3.256-1.484 5.125-1.484 1.868 0 3.688.527 5.125 1.484 1.617 1.079 2.646 2.615 2.975 4.444a1.38 1.38 0 01-.28 1.137 1.21 1.21 0 01-.945.435z" _fill="#fff"/>'
  }
})
