/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backOut': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.233 4.455c.44.439.44 1.151 0 1.59L6.278 12l5.955 5.954a1.125 1.125 0 01-1.591 1.591l-6.75-6.75a1.125 1.125 0 010-1.59l6.75-6.75c.44-.44 1.152-.44 1.591 0z" _fill="#4D4C4C"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 12c0-.621.504-1.125 1.125-1.125h13.688a1.125 1.125 0 010 2.25H5.624A1.125 1.125 0 014.5 12z" _fill="#4D4C4C"/>'
  }
})
