/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/redarrowreverse': {
    width: 44,
    height: 63,
    viewBox: '0 0 44 63',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.23 0l-3.06 32.785H.886l21.5 29.988 21.5-29.988H30.6L27.542 0H17.23z" _fill="#F11C33"/>'
  }
})
