/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/cash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="12" _fill="#E6ECF4"/><path pid="1" d="M18 16.5H6a.5.5 0 01-.5-.5.5.5 0 01.5-.5h12a.5.5 0 01.354.146.5.5 0 010 .708.5.5 0 01-.354.146zM17 18H7a.5.5 0 01-.5-.5.5.5 0 01.5-.5h10a.5.5 0 01.354.146.5.5 0 010 .708A.5.5 0 0117 18zM5 12.5h-.5V14a1 1 0 001 1H7v-.5a2.002 2.002 0 00-2-2z" _fill="#222F5A"/><path pid="2" d="M19 11.5h.5v-2H19a3.003 3.003 0 01-3-3V6H8v.5a3.003 3.003 0 01-3 3h-.5v2H5a3.003 3.003 0 013 3v.5h8v-.5a3.003 3.003 0 013-3zm-7 2a3 3 0 01-2.772-1.852 3 3 0 012.187-4.09 3 3 0 013.08 1.275A3 3 0 0115 10.5a3.003 3.003 0 01-3 3z" _fill="#F11C33"/><path pid="3" d="M12 12.5a2 2 0 100-4 2 2 0 000 4zm5 2v.5h1.5a1 1 0 001-1v-1.5H19a2.002 2.002 0 00-2 2zm2-6h.5V7a1 1 0 00-1-1H17v.5a2.002 2.002 0 002 2zm-12-2V6H5.5a1 1 0 00-1 1v1.5H5a2.002 2.002 0 002-2z" _fill="#222F5A"/>'
  }
})
