/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/smallsms': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.324 1.333A.667.667 0 006.657 2v5.588l1.702-.851a.667.667 0 01.298-.07h5.333A.667.667 0 0014.657 6V2a.667.667 0 00-.667-.667H7.324zM5.91.586A2 2 0 017.324 0h6.666a2 2 0 012 2v4a2 2 0 01-2 2H8.814L6.288 9.263a.667.667 0 01-.964-.596V2A2 2 0 015.91.586z" _fill="#111"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1.243 1.919a2 2 0 011.414-.586h1a.667.667 0 010 1.334h-1a.667.667 0 00-.666.666V14a.667.667 0 00.666.667h7.334a.667.667 0 00.666-.667v-4a.667.667 0 011.334 0v4a2 2 0 01-2 2H2.657a2 2 0 01-2-2V3.333a2 2 0 01.586-1.414z" _fill="#111"/><circle pid="2" cx="6.657" cy="12.667" _fill="#111" r=".667"/>'
  }
})
