/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/mesfactur': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.375 7H9a1.5 1.5 0 01-1.5-1.5V1.125A.125.125 0 007.375 1H4.5a2 2 0 00-2 2v10a2 2 0 002 2h7a2 2 0 002-2V7.125A.125.125 0 0013.375 7zM10.5 12h-5a.5.5 0 01-.5-.5.5.5 0 01.5-.5h5a.5.5 0 110 1zm0-2.5h-5a.5.5 0 110-1h5a.5.5 0 01.354.854.5.5 0 01-.354.146z" _fill="currentColor"/><path pid="1" d="M13.1 5.894L8.607 1.4a.063.063 0 00-.106.044V5.5A.5.5 0 009 6h4.057a.063.063 0 00.044-.106z" _fill="currentColor"/>'
  }
})
