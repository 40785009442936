/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/datatablmb': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="3" r="3" _fill="#4D4C4C"/><circle pid="1" cx="12" cy="12" r="3" _fill="#4D4C4C"/><circle pid="2" cx="12" cy="21" r="3" _fill="#4D4C4C"/>'
  }
})
