/* eslint-disable */
require('./amburger')
require('./avatar')
require('./backOut')
require('./battery')
require('./Bg')
require('./bgmobile')
require('./closeModal')
require('./compt2')
require('./compteur1')
require('./compteur1mobile')
require('./dashbIcons')
require('./dette')
require('./electricBill')
require('./findmeter')
require('./findtransaction')
require('./Group')
require('./Group156')
require('./icons')
require('./kkiagold')
require('./kkiapay-coin')
require('./login')
require('./momo')
require('./opensi')
require('./pci')
require('./SBEEA1')
require('./smartphone')
