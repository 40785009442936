/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/moyenspaiment': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.784 10.21a.772.772 0 00-1.09.096.508.508 0 01-.071.072.483.483 0 01-.68-.072.773.773 0 00-.597-.284.78.78 0 00.005 1.556.753.753 0 00.597-.28.478.478 0 01.371-.173.465.465 0 01.371.168c.034.039.063.073.101.102a.772.772 0 001.089-.097.772.772 0 00-.096-1.089zM14.9 2.886H1.095A1.083 1.083 0 000 3.96v.708h16v-.708c0-.602-.496-1.074-1.099-1.074zm1.094 2.746H0v1.252h15.995V5.633z" _fill="currentColor"/><path pid="1" d="M0 7.85v4.58c0 .608.487 1.1 1.094 1.104h13.803a1.108 1.108 0 001.098-1.103V7.849H0zm14.738 3.92a1.743 1.743 0 01-2.419.468c-.289.198-.626.299-.973.299a1.743 1.743 0 010-3.483c.347 0 .684.1.973.298.593-.4 1.364-.4 1.951 0a1.743 1.743 0 01.468 2.419z" _fill="currentColor"/>'
  }
})
