/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/lock': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M28.75 15H27.5V8.75a7.5 7.5 0 00-15 0V15h-1.25a5.005 5.005 0 00-5 5v13.75a5.005 5.005 0 005 5h17.5a5.005 5.005 0 003.534-1.466 5.005 5.005 0 001.466-3.534V20a5.005 5.005 0 00-1.466-3.534A5.005 5.005 0 0028.75 15zM25 15H15V8.75a5 5 0 0110 0V15z" _fill="#222F5A"/>'
  }
})
