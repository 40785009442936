/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/chevrondown': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.712 6.525a.938.938 0 011.326 0L10 11.487l4.962-4.962a.938.938 0 011.326 1.325l-5.625 5.625a.938.938 0 01-1.326 0L3.712 7.85a.938.938 0 010-1.325z" _fill="#000"/>'
  }
})
