import { io } from "socket.io-client";

const IO_URL = process.env.VUE_APP_IO_URL || 'http://localhost:3000'
const socket = io(IO_URL, {transports: ['polling']})
socket.on('connect', function(){
  console.log('Connected');
  
})
socket.on('disconnect', function(){
  console.log('Disconnect');
  
})
socket.on('connect_error',function(error){
  console.log('connect_error', error);
  
})

export default socket;

export {socket, io}