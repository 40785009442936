/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/id': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="12" _fill="#E6ECF4"/><path pid="1" d="M14.395 6.018C13.787 5.362 12.938 5 12 5c-.943 0-1.795.36-2.4 1.012-.612.66-.91 1.556-.84 2.524C8.899 10.446 10.352 12 12 12c1.648 0 3.099-1.553 3.24-3.463.07-.96-.23-1.854-.845-2.519z" _fill="#F11C33"/><path pid="2" d="M17.5 19h-11a.97.97 0 01-.756-.348 1.104 1.104 0 01-.224-.91c.263-1.463 1.087-2.692 2.38-3.555C9.05 13.422 10.505 13 12 13c1.495 0 2.95.422 4.1 1.188 1.294.862 2.117 2.091 2.38 3.554.061.335-.02.667-.224.91A.968.968 0 0117.5 19z" _fill="#222F5A"/>'
  }
})
