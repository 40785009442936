/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/mescompteur': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.297 1.333H2.547A.548.548 0 002 1.881V14.12c0 .302.246.548.548.548h10.749a.548.548 0 00.548-.548V1.88a.548.548 0 00-.548-.548zm-6.73 12.452l1.22-3.314H6.375l1.052-3.239h1.768L7.968 9.744 9.7 9.78l-3.134 4.005zm3.682-7.423H3.98a.183.183 0 110-.365h6.269a.183.183 0 110 .365zm1.232.2a.384.384 0 110-.766.384.384 0 010 .767zm.784-2.16a.365.365 0 01-.366.365H3.945A.365.365 0 013.58 4.4V2.958a.365.365 0 01.365-.365H11.9a.365.365 0 01.366.365v1.444z" _fill="currentColor"/>'
  }
})
