/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/errorIcon': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.128 20.336L15.726 4.21a2.685 2.685 0 00-4.64 0L1.707 20.296v.002l-.023.038a2.687 2.687 0 002.32 4.041h18.804a2.687 2.687 0 002.32-4.041zm-7.567-.314a1.105 1.105 0 01-1.563 0l-2.592-2.593-2.592 2.593a1.105 1.105 0 01-1.857-.492 1.105 1.105 0 01.294-1.071l2.593-2.594-2.5-2.5a1.105 1.105 0 011.562-1.564l2.5 2.501 2.5-2.501a1.105 1.105 0 011.857.492 1.105 1.105 0 01-.294 1.071l-2.5 2.501 2.592 2.594a1.106 1.106 0 010 1.563z" _fill="#EB5757"/>'
  }
})
