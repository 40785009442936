/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/send': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" d="M18.608 7.87l-.007-.004L1.92.946a.92.92 0 00-.868.085.963.963 0 00-.435.805V6.26a.938.938 0 00.762.92l9.1 1.683a.156.156 0 010 .307l-9.1 1.683a.937.937 0 00-.762.92V16.2a.92.92 0 00.413.769.935.935 0 00.517.156.96.96 0 00.372-.075L18.6 10.17l.007-.004a1.25 1.25 0 00.55-1.836 1.25 1.25 0 00-.55-.46z" _fill="#fff"/>'
  }
})
