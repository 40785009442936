/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/downloadblue': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.625 7.5H12.75v7.19l2.47-2.47a.75.75 0 011.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 01.534-1.266.75.75 0 01.526.206l2.47 2.47V7.5H6.375a2.628 2.628 0 00-2.625 2.625v9.75A2.628 2.628 0 006.375 22.5h11.25a2.628 2.628 0 002.625-2.625v-9.75A2.628 2.628 0 0017.625 7.5zM12.75 2.25A.75.75 0 0012 1.5a.75.75 0 00-.75.75V7.5h1.5V2.25z" _fill="#2D7BF7"/>'
  }
})
