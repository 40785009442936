/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/eyeoff': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.875 17.5a.623.623 0 01-.442-.183L2.683 3.567a.625.625 0 01.884-.884l13.75 13.75a.625.625 0 01-.442 1.067zm-7.188-5.162l-2.022-2.023a.078.078 0 00-.133.066 2.504 2.504 0 002.09 2.09.078.078 0 00.08-.043.078.078 0 00-.014-.09zm.626-4.676l2.026 2.025a.078.078 0 00.132-.065A2.505 2.505 0 0010.38 7.53a.078.078 0 00-.068.132z" _fill="#A5BCE0"/><path pid="1" d="M19.18 10.678a1.258 1.258 0 00-.004-1.358c-1.034-1.598-2.375-2.956-3.878-3.927-1.665-1.075-3.501-1.643-5.31-1.643a8.849 8.849 0 00-2.806.46.156.156 0 00-.061.26l1.845 1.845a.156.156 0 00.15.04 3.75 3.75 0 014.53 4.532.155.155 0 00.042.149l2.654 2.656a.156.156 0 00.211.01 13.43 13.43 0 002.627-3.024zM10 13.75a3.75 3.75 0 01-2.948-1.432 3.75 3.75 0 01-.696-3.202.156.156 0 00-.041-.149L3.702 6.355a.156.156 0 00-.21-.009c-.954.813-1.837 1.802-2.644 2.958a1.247 1.247 0 00-.025 1.388c1.032 1.614 2.359 2.974 3.839 3.932 1.668 1.079 3.459 1.626 5.325 1.626a9.309 9.309 0 002.837-.451.157.157 0 00.105-.112.156.156 0 00-.042-.148l-1.854-1.854a.157.157 0 00-.15-.04 3.746 3.746 0 01-.884.105z" _fill="#A5BCE0"/>'
  }
})
