
const state = {
    counter: {
        number: null,
        cUser: null,
        debt: null,
        transacId: null,
    },
    jobFailedTransact: {
        amount: null,
        meterNumb: null,
        meterOwner: null,
        phone: null,
        countryCode: null
    },
    paySuccessData: {
        counterNum: null,
        amount: null,
        codeSts: null,
        kwh: null,
        entretien:null,
        dettes: null,
        tva: null,
        fraisMtn: null,
        total: null,
        id: null
    },
};

const getters = {
    getCounterFirstPur: state => {
        return state.counter
    },

    getPaySuccessData: state => {
        return state.paySuccessData
    },
    getJobFailedTransact: state => {
        return state.jobFailedTransact
    },
}

const mutations = {
    mutCounterFirstPur (state, meter) {
        state.counter = {
            number: meter.number,
            cUser: meter.user,
            debt: meter.debt,
            transacId: meter.transacId,
        }
    },

    mutJobFailedTransact (state, transact) {
        state.jobFailedTransact = transact
    },
    
    mutPaySuccessData: (state, purchaseData) => {
        state.paySuccessData = {
            counterNum: purchaseData.counterNum,
            amount: purchaseData.amount,
            codeSts: purchaseData.codeSts,
            kwh: purchaseData.kwh,
            entretien: purchaseData.entretien,
            dettes: purchaseData.dettes,
            tva: purchaseData.tva,
            fraisMtn: purchaseData.fraisMtn,
            total: purchaseData.total,
            id: purchaseData.id
        }
    },
}


export default{
    state,
    getters,
    mutations

}