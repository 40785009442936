/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/rechargecompteu': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" d="M15.459 1.5H3.366a.617.617 0 00-.616.616v13.768a.617.617 0 00.616.616H15.46a.617.617 0 00.617-.616V2.116a.617.617 0 00-.617-.616zM7.887 15.508l1.374-3.728H7.672l1.183-3.644h1.989l-1.38 2.825 1.948.041-3.525 4.506zm4.143-8.351H4.978a.205.205 0 110-.411h7.052a.205.205 0 110 .41zm1.386.226a.431.431 0 110-.863.431.431 0 010 .863zm.882-2.432a.411.411 0 01-.411.411H4.939a.411.411 0 01-.411-.41V3.326c0-.227.184-.41.41-.41h8.949c.227 0 .41.183.41.41v1.624z" _fill="#fff"/>'
  }
})
