import Vue from 'vue';
import Vuex from 'vuex';
import counterFirst from './modules/counterFirst';
import landing from './modules/landing';
import purchases from './modules/purchases';
import counters from './modules/counters';
import billFirstPur from './modules/billFirstPur';

import paymentMethods from './modules/paymentMethods'
import auth from './modules/auth';

Vue.use(Vuex);

export const store = new Vuex.Store({
         state: {
           prevCounterNumber: "",
           paymentStep: false,
           pleaseWaitStep: false,
           findReloadStep: false,
           dashboardPleaseWait: false,
           dashboardPaymentStep: false,
           isOpen: false,
           signSuccess: false,
           subscribeByPhone: "",
           codeVerify: false,
           pwdResetMail: "",
           pwdResetToken: "",
           userSign: {
             email: "",
             countryCode: "",
             phone: "",
           },
           billProcessBoolean: true,
         },
         getters: {
           getBillProcessBoolean(state) {
             return state.billProcessBoolean;
           },
           getIsOpen(state) {
             return state.isOpen;
           },
           getPrevCounterNumber(state) {
             return state.prevCounterNumber;
           },
           getPaymentStep(state) {
             return state.paymentStep;
           },
           getPleaseWaitStep(state) {
             return state.pleaseWaitStep;
           },
           getFindReloadStep(state) {
             return state.findReloadStep;
           },
           getDashboardPleaseWait(state) {
             return state.dashboardPleaseWait;
           },
           getdashboardPaymentStep(state) {
             return state.dashboardPaymentStep;
           },
           getSIgnSuccess(state) {
             return state.signSuccess;
           },
           getCodeVerify(state) {
             return state.codeVerify;
           },
           getUser(state) {
             return state.userSign;
           },
           getPwdResetMail(state) {
             return state.pwdResetMail;
           },
           getPwdResetToken(state) {
             return state.pwdResetToken;
           },
         },
         mutations: {
           mutBillProcessBoolean(state, bool) {
             this.state.billProcessBoolean = bool;
           },
           mutIsOpen(state, bool) {
             this.state.isOpen = bool;
           },
           mutPaymentStep(state, bool) {
             this.state.paymentStep = bool;
           },
           mutPleaseWaitStep(state, bool) {
             this.state.pleaseWaitStep = bool;
           },
           mutFindReloadStep(state, bool) {
             this.state.findReloadStep = bool;
           },
           mutDashboardPleaseWait(state, bool) {
             this.state.dashboardPleaseWait = bool;
           },
           mutdashboardPaymentStep(state, bool) {
             this.state.dashboardPaymentStep = bool;
           },
           mutSignSuccess(state, bool) {
             this.state.signSuccess = bool;
           },
           mutSubscribeByPhone(state, bool) {
             this.state.subscribeByPhone = bool;
           },
           mutCodeVerify(state, bool) {
             this.state.codeVerify = bool;
           },
           mutUser(state, userInfo) {
             this.state.userSign = {
               email: userInfo.email,
               countryCode: userInfo.countryCode,
               phone: userInfo.phone,
             };
           },
           mutPwdResetMail(state, mail) {
             this.state.pwdResetMail = mail;
           },
           mutPwdResetToken(state, code) {
             this.state.pwdResetToken = code;
           },
           mutPrevCounterNumber(state, number) {
             this.state.prevCounterNumber = number;
           },
         },

         actions: {},

         modules: {
           billFirstPur,
           landing,
           counterFirst,
           purchases,
           counters,
           paymentMethods,
           auth,
         },
       });