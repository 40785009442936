/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/search': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.392 2.713a6.085 6.085 0 116.761 10.12 6.085 6.085 0 01-6.761-10.12zm3.38.1a4.96 4.96 0 014.96 4.96m-4.96-4.96A4.96 4.96 0 002.909 8.74a4.96 4.96 0 009.825-.967" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.495 11.495a.563.563 0 01.796 0l3.857 3.857a.563.563 0 11-.796.795l-3.857-3.857a.563.563 0 010-.795z" _fill="#000"/>'
  }
})
