import axios from 'axios'
const state = {

    payMethods: [],
    payMethodId: '',
    addPayMethod: false,
    trashPayMethod: false,
};

const getters = {
    getAddPayMethod: state => {
        return state.addPayMethod
    },
    getTrashPayMethod: state => {
        return state.trashPayMethod
    },
    getPayMethods: state => {
        return state.payMethods
    },
    getPayMethodId: state => {
        return state.payMethodId
    },
}

const mutations = {
    mutAddPayMethod (state, bool) {
        state.addPayMethod = bool
    },
    mutTrashPayMethod (state, bool) {
        state.trashPayMethod = bool
    },
    mutPayMethodId (state, id) {
        state.payMethodId = id
    },
    mutPayMethodUpdate (state) {
        axios.delete(`/payments/${state.counterId}`)
        .then(res=>{
            console.log(res.data)
            state.payMethods = state.payMethods.filter(
                payMethod => {
                    return payMethod.id != state.payMethodId;
                }
            );
        })
        .catch(err => {
            console.log(err.response)
        })
        
    },
    mutAddPaymentMethod (state, method) {
            const verifyIfExist = state.payMethods.filter(payM =>
                payM.account == method.account  );
            if (verifyIfExist.length == 0) {
                if(method.principal == true) {
                    state.payMethods.forEach(payM =>
                        payM.principal = false  );
                }
                state.payMethods.push(
                    {
                        id: state.payMethods.length +1,
                        account: method.account,
                        type: method.type,
                        principal: method.principal,
                        name: method.name,
                        email: method.email,
                        active: false
                    }
                )
            }
        
        
      },
    /* addPaymentMethod (state) {
        state.payMethods = [];
        for (let i = 0; i< 1; i++){
            state.payMethods.push(
                {
                    id: i,
                    account: 'Visa-9758',
                    type: 'Visa',
                    principal: true,
                    name: 'Jean Dossou',
                    email: '',
                    active: false
                }
            )
        }
        for (let i = 1; i< 2; i++){
            state.payMethods.push(
                {
                    id: i,
                    account: '97000000',
                    type: 'Mobile',
                    principal: false,
                    name: 'Jim Dossou',
                    email: '',
                    active: false
                }
            )
        }
      } */
}


export default{
    state,
    getters,
    mutations

}