/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/save': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" d="M35.031 40.969H20.97a1.17 1.17 0 00-1.172 1.172 1.17 1.17 0 001.172 1.172H35.03a1.17 1.17 0 001.172-1.172 1.17 1.17 0 00-1.172-1.172zm0-9.375H20.97a1.17 1.17 0 00-1.172 1.172 1.17 1.17 0 001.172 1.172H35.03a1.17 1.17 0 001.172-1.172 1.17 1.17 0 00-1.172-1.172zm0 4.687H20.97a1.17 1.17 0 00-1.172 1.172 1.17 1.17 0 001.172 1.172H35.03a1.17 1.17 0 001.172-1.172 1.17 1.17 0 00-1.172-1.172z" _fill="#F11C33"/><path pid="1" d="M33.86 8H17.452v10.156H33.86V8z" _fill="#222F5A"/><path pid="2" d="M47.656 14.594l-6.25-6.25A1.17 1.17 0 0040.578 8h-4.375v11.328a1.17 1.17 0 01-1.172 1.172h-18.75a1.17 1.17 0 01-1.172-1.172V8H9.172A1.17 1.17 0 008 9.172v37.656A1.17 1.17 0 009.172 48h37.656A1.17 1.17 0 0048 46.828V15.422c0-.313-.125-.61-.344-.828zm-9.11 31.062H17.454V29.25h21.094v16.406z" _fill="#222F5A"/>'
  }
})
