/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/dette': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="12" _fill="#E6ECF4"/><path pid="1" _fill="#F11C33" d="M11 9h2v8h-2z"/><path pid="2" d="M18.968 17.993L12.388 5.77a.25.25 0 00-.348-.096.25.25 0 00-.092.096L5.366 17.993a.25.25 0 00.22.368H18.75a.251.251 0 00.213-.122.25.25 0 00.005-.246zm-6.175-1.134h-1.25v-1.25h1.25v1.25zm-.125-1.998h-1l-.188-5h1.375l-.187 5z" _fill="#222F5A"/>'
  }
})
