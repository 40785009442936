/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/beninflag': {
    width: 23,
    height: 16,
    viewBox: '0 0 23 16',
    data: '<path pid="0" d="M2.25.5A2.25 2.25 0 000 2.75v10.5a2.25 2.25 0 002.25 2.25h18a2.25 2.25 0 002.25-2.25V2.75A2.25 2.25 0 0020.25.5h-18z" _fill="#E8112D"/><path pid="1" d="M2.25.5A2.25 2.25 0 000 2.75V8h22.5V2.75A2.25 2.25 0 0020.25.5h-18z" _fill="#FCD116"/><path pid="2" d="M2.25.5A2.25 2.25 0 000 2.75v10.5a2.25 2.25 0 002.25 2.25H9V.5H2.25z" _fill="#008751"/>'
  }
})
