/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/offline': {
    width: 8,
    height: 8,
    viewBox: '0 0 8 8',
    data: '<circle pid="0" cx="4" cy="4" r="4" _fill="#F11C33"/>'
  }
})
