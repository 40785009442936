/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/refresh': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 11.83a.688.688 0 01-.225.528l-2.95 2.92a.717.717 0 01-1.05 0l-2.9-2.92c-.275-.302-.275-.78 0-1.057h2.3c-.2-2.77-2.425-4.934-5.175-4.934-1.55 0-2.95.705-3.9 1.812L6.225 6.77C7.6 5.083 9.675 4 12 4c4 0 7.275 3.223 7.475 7.3h2.3c.15.127.225.328.225.53zm-19.775.226H4.5c.2 4.079 3.475 7.301 7.5 7.301 2.325 0 4.4-1.082 5.775-2.77L15.9 15.179a5.138 5.138 0 01-3.9 1.813c-2.75 0-4.975-2.165-5.175-4.935h2.3c.275-.302.275-.78 0-1.057l-2.9-2.92c-.15-.152-.325-.227-.525-.227s-.375.075-.525.227l-2.95 2.92a.727.727 0 00-.225.529c0 .2.075.402.225.528z" _fill="#0965F6"/><path pid="1" d="M22 11.83a.688.688 0 01-.225.528l-2.95 2.92a.717.717 0 01-1.05 0l-2.9-2.92c-.275-.302-.275-.78 0-1.057h2.3c-.2-2.77-2.425-4.934-5.175-4.934-1.55 0-2.95.705-3.9 1.812L6.225 6.77C7.6 5.083 9.675 4 12 4c4 0 7.275 3.223 7.475 7.3h2.3c.15.127.225.328.225.53zm-19.775.226H4.5c.2 4.079 3.475 7.301 7.5 7.301 2.325 0 4.4-1.082 5.775-2.77L15.9 15.179a5.138 5.138 0 01-3.9 1.813c-2.75 0-4.975-2.165-5.175-4.935h2.3c.275-.302.275-.78 0-1.057l-2.9-2.92c-.15-.152-.325-.227-.525-.227s-.375.075-.525.227l-2.95 2.92a.727.727 0 00-.225.529c0 .2.075.402.225.528z" _fill="#0965F6"/>'
  }
})
