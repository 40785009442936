/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/trash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.25 4.5h-4.5V3.375A1.875 1.875 0 0013.875 1.5h-3.75A1.875 1.875 0 008.25 3.375V4.5h-4.5a.75.75 0 000 1.5h.797l.89 14.293c.067 1.259 1.032 2.207 2.25 2.207h8.625c1.225 0 2.17-.927 2.25-2.203L19.453 6h.797a.75.75 0 00.53-1.28.75.75 0 00-.53-.22zm-11.223 15H9a.75.75 0 01-.75-.723l-.375-10.5a.75.75 0 011.5-.054l.375 10.5a.75.75 0 01-.723.777zm3.723-.75a.75.75 0 01-.75.75.75.75 0 01-.75-.75V8.25a.75.75 0 011.28-.53.75.75 0 01.22.53v10.5zm1.5-14.25h-4.5V3.375A.37.37 0 0110.125 3h3.75a.371.371 0 01.348.23.37.37 0 01.027.145V4.5zm1.5 14.277a.75.75 0 01-.75.723h-.027a.75.75 0 01-.676-.488.75.75 0 01-.047-.289l.375-10.5a.751.751 0 01.488-.676.75.75 0 01.811.192.75.75 0 01.201.538l-.375 10.5z" _fill="#F11C33"/>'
  }
})
