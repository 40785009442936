/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/mail': {
    width: 18,
    height: 14,
    viewBox: '0 0 18 14',
    data: '<path pid="0" d="M15.563.125H2.437A2.19 2.19 0 00.25 2.313v9.374a2.19 2.19 0 002.188 2.188h13.124a2.19 2.19 0 002.188-2.188V2.313A2.19 2.19 0 0015.562.124zm-.554 3.618L9.384 8.118a.625.625 0 01-.768 0L2.991 3.743a.625.625 0 11.768-.986L9 6.833l5.241-4.076a.625.625 0 01.768.986z" _fill="#4661B9"/>'
  }
})
