/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icons/MTNmomo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12" r="12" _fill="#E6ECF4"/><path pid="1" d="M13.133 11.93l3.746 5.302c.232.331-.026.768-.452.768h-2.105a.556.556 0 01-.452-.224l-2.867-3.992a.578.578 0 00-.814-.095l-1.511 1.217a.418.418 0 00-.168.271l-.53 2.41a.529.529 0 01-.529.401H5.54c-.349 0-.594-.283-.53-.59L7.412 6.413a.529.529 0 01.53-.401H9.84c.349 0 .594.283.53.59l-.75 3.45c-.103.46.504.779.892.472l5.424-4.394a.585.585 0 01.362-.13h2.027c.504 0 .736.567.362.874l-5.45 4.405a.476.476 0 00-.104.65z" _fill="#F11C33"/>'
  }
})
