/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashbIcons/profiluser': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 10a4.375 4.375 0 004.29-5.229 4.375 4.375 0 00-3.436-3.437A4.375 4.375 0 1010 10zm0 1.25c-2.712 0-8.125 1.675-8.125 5v2.5h16.25v-2.5c0-3.325-5.413-5-8.125-5z" _fill="#F2994A"/>'
  }
})
